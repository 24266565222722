import React from 'react'
import "normalize.css"
import skillStyles from './sass/skills.module.scss'

const Skills = () => {
    return (
        <div className={skillStyles.sectionFeatures}>
            <div className={skillStyles.row}>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/js.svg")} className={skillStyles.image}  alt="js"/>
                        <h3>Vanilla Javascript</h3>
                        <p>Ability to write plain Javascript with no special or extra features.</p>
                    </div>
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/node.svg")} className={[skillStyles.image, skillStyles.nodeLogo].join(' ')} alt="node"/>
                        <img src={require("../posts/assets/python.svg")} className={skillStyles.image} alt="python"/>
                        <h3>Node | Python</h3>
                        <p>Handle Node and Python and its packages for back-end development.</p>                            
                    </div>    
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/react-black.svg")} className={skillStyles.image} alt="react"/>
                        <img src={require("../posts/assets/jquery.svg")} className={skillStyles.image} alt="jquery"/>
                        <h3>React | jquery</h3>
                        <p>Familiar with React and Jquery libraries to speed-up and simplify front-end development.</p>                            
                    </div>    
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/firebase.png")} className={skillStyles.image} alt="firebase"/>
                        <img src={require("../posts/assets/sql.svg")} className={skillStyles.image} alt="sql"/>
                        <h3>Firebase | SQL</h3>
                        <p>Handling relational and non-relational databases and its implementations.</p>
                    </div>
                </div>
            </div>
            <div className={skillStyles.row}>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/gatsby.svg")} className={skillStyles.image} alt="gatsby"/>
                        <img src={require("../posts/assets/graphql.svg")} className={skillStyles.image} alt="graphql"/>
                        <h3>Gatsby | Graphql</h3>
                        <p>Ability to use gatsby and graphQL to speed up development and fetch data easily.</p>
                    </div>
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/github.svg")} className={skillStyles.image} alt="git"/>
                        <img src={require("../posts/assets/heroku.svg")} className={skillStyles.image} alt="hroku"/>
                        <h3>Github | Heroku</h3>
                        <p>Deployments, tracking changes, and to colaborate on projects with team members.</p>
                    </div>
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/html.svg")} className={[skillStyles.image, skillStyles.htmlLogo].join(' ')} alt="html"/>
                        <img src={require("../posts/assets/css3.svg")} className={skillStyles.image} alt="css3"/>
                        <h3>HTML5 | CSS3</h3>
                        <p>Structuring, displaying content as well as the presentation and design.</p>
                    </div>
                </div>
                <div className={skillStyles.col4}>
                    <div className={skillStyles.featureBox}>
                        <img src={require("../posts/assets/sass.svg")} className={skillStyles.image} alt="sass"/>
                        <img src={require("../posts/assets/less.svg")} className={skillStyles.image} alt="less"/>
                        <h3>Sass | Less</h3>
                        <p>Pre-processors that makes css more powefull with functions and much more!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills